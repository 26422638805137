<template>
    <div class="flex flex-col" style="width: 400px; height: 580px">
        <header class="text-header-blue text-xl font-semibold mb-3">Företagsuppgifter</header>
        <el-form-item class="w-full" label="Organisationsnummer" prop="registrationNumber">
            <el-input :disabled="true" v-model="clientInfo.registrationNumber" />
        </el-form-item>
        <el-form-item class="w-full" label="Företagsnamn" prop="name">
            <el-input :disabled="true" v-model="clientInfo.name" />
        </el-form-item>
        <el-form-item class="w-full" label="Säte" prop="residence">
            <el-input v-model="clientInfo.residence" />
        </el-form-item>
        <el-form-item class="w-full" label="Räkenskapsår" prop="financialYear">
            <div class="flex">
                <el-input :disabled="true" v-model="financialyearRange" />
                <el-tooltip content="Show All Financial Years" placement="top">
                    <el-button class="ml-2" size="mini" type="primary"> <i class="fas fa-info-circle mr-1" /> Se alla</el-button>
                    <div slot="content">
                        <ul>
                            <li class="mb-2" v-for="year in financialYears" :key="year.financialYearId">{{ formatDate(year.startDate) }} - {{ formatDate(year.endDate) }}</li>
                        </ul>
                    </div>
                </el-tooltip>
            </div>
        </el-form-item>
        <el-form-item>
            <el-checkbox v-model="clientInfo.isK3">Årsbokslut K3?</el-checkbox>
        </el-form-item>
        <el-form-item>
            <RadioButtonList :entries="yesNoHasAuditor" v-model="clientInfo.hasAudit" :horizontal="true" label="Har revisor?" />
        </el-form-item>
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {
        RadioButtonList: () => import("../../../../components/content/RadioButtonList.vue"),
    },
    props: {
        clientInfo: {
            type: Object,
        },
        financialYears: {
            type: Array,
        },
        financialyearRange: {
            type: String,
        },
        yesNoHasAuditor: {
            type: Array,
        },
    },
    data() {
        return {
            showFinancialYears: false,
        };
    },
    methods: {
        toggleShowFinancialYears() {
            this.showFinancialYears = !this.showFinancialYears;
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },
};
</script>
